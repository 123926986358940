<template>
	<memory-form :memory="memory" :id="id"></memory-form>
</template>

<script>
import MemoryForm from './Form'
import Models from '../../models'
import {sync} from "vuex-pathify";

export default {
	name: "EditMemory",
	metaInfo() {
		return {
			title: this.memory?.title || 'Memory',
		}
	},
	props: ['id'],
	components:{
		MemoryForm
	},
	computed:{
		user: sync('user/user')
	},
	beforeMount() {
		if(this.user.subscription_status === 'Lapsed'){
			this.$router.push({name: 'Account'});
		}
		this.memory = Models.Memory(new Date().toISOString().substr(0, 10))
	},
	mounted() {
		this.getMemory()
	},
	data: () => ({
		memory: null,
	}),
	methods: {
		getMemory() {
			this.$api.memories.show(this.id)
				.then(response => this.memory = response.data)
				.catch(error => console.log(error))
		},
	}
}
</script>